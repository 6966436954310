import { ArticleTeaserDefaultTheme } from '@/components/ArticleTeaser/Default/theme';
import { SkeletonTheme } from '@/components/Skeleton/theme';
import { tw } from '@/utils/tw';

const Skeleton = tw.theme({
  extend: SkeletonTheme,
  base: ['w-full', 'h-[315px]'],
});

const ArticleTeaser = tw.theme({
  extend: ArticleTeaserDefaultTheme,
  slots: {
    base: ['w-full', 'container-inline', 'auto-rows-min', 'gap-2', 'grid grid-cols-2', 'sm:grid-cols-1'],
    description: ['line-clamp-3'],
  },
});

const RecommendedArticles = tw.theme({
  slots: {
    base: ['relative', 'wings', 'bg-primary-200', 'wings-primary-200', 'py-4', 'md:py-10', 'px-6'],
    headline: ['block', 'mb-4.5', 'text-headline-sm', 'sm:text-headline'],
    list: ['grid', 'sm:grid-cols-2', 'md:grid-cols-4', 'gap-4'],
    listItem: [],
  },
});

export default Object.assign(RecommendedArticles, { ArticleTeaser, Skeleton });
