import { DirektTeaser } from '@/components/DirektTeaser';
import { Icon } from '@/components/Icon';
import { LabradorComponent } from '@/types/component';
import { http } from '@/utils/http';
import { MINUTE } from '@/utils/ttl';
import type { DirektPostInterface } from 'pages/api/direkt';
import { useEffect, useState } from 'react';

export const LabradorDirektTeaser: LabradorComponent = ({ data, ...props }) => {
  const [posts, setPosts] = useState<DirektPostInterface[]>([]);

  useEffect(() => {
    const getPosts = () =>
      http.get('/api/direkt').then((response) => {
        setPosts(response?.data);
      });

    getPosts();
    const id = setInterval(getPosts, MINUTE * 1000);

    return () => clearInterval(id);
  }, []);

  return (
    <DirektTeaser
      headline={data.headline || 'Direct'}
      posts={posts.map(({ post, postId, publishedAt }) => ({
        text: post,
        href: `/direkt?ifr-dir-postId=${postId}`,
        time: publishedAt,
      }))}
      button={{
        content: (
          <>
            <span>Visa fler</span>
            <Icon name="teaserArrow" options={{ size: 'extraSmall' }} />
          </>
        ),
        href: '/direkt',
      }}
      {...props}
    />
  );
};
