import { Link, LinkProps } from '@/components/Link';
import type { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import React, { ComponentProps } from 'react';
import { ExpertSlider } from './ExpertSlider';

interface Slide {
  image?: ComponentProps<typeof ExpertSlider.Image>;
  caption?: React.ReactNode;
  description?: React.ReactNode;
  href?: LinkProps['href'];
}

export interface StandaloneExpertSliderProps extends StandaloneComponentProps<typeof ExpertSlider> {
  headline?: React.ReactNode;
  slides?: Slide[];
}

export const StandaloneExpertSlider: StandaloneComponent<StandaloneExpertSliderProps> = ({
  headline,
  slides,
  ...props
}) => {
  if (!slides || !slides.length) return <></>;

  return (
    <ExpertSlider {...props}>
      <ExpertSlider.Headline>{headline}</ExpertSlider.Headline>
      <ExpertSlider.Slider
        slides={slides.map((slide, index) => (
          <React.Fragment key={index}>
            {slide.href && <Link href={slide.href} options={{ cover: true }} />}
            {slide.image?.src && (
              <ExpertSlider.Image {...mergeProps({ options: props.options?.$image }, slide.image)} />
            )}
            {slide.caption && <ExpertSlider.Caption>{slide.caption}</ExpertSlider.Caption>}
            {slide.description && <ExpertSlider.Description>{slide.description}</ExpertSlider.Description>}{' '}
          </React.Fragment>
        ))}
      ></ExpertSlider.Slider>
    </ExpertSlider>
  );
};
