import { tw } from '@/utils/tw';
import BaseDirectTeaserTheme from 'base/components/DirektTeaser/theme';

const Button = tw.theme({
  extend: BaseDirectTeaserTheme.Button,
  base: ['mx-auto', 'w-fit', 'flex'],
  defaultVariants: {
    colors: 'secondary',
  },
});

const DirectTeaserTheme = tw.theme({
  extend: BaseDirectTeaserTheme,
  slots: {
    base: ['border-b-primary-700', 'px-0', 'sm:max-w-col-lg-8'],
    headline: ['after:h-0.5', 'after:flex-1', 'after:bg-primary-700', 'after:block', 'after:ml-1.5'],
    header: ['flex-2', 'text-headline-3xs'],
    item: ['border-gray-200'],
    time: ['text-gray-600', 'text-headline-3xs'],
    itemText: ['sm:text-headline-xs', 'text-headline-2xs'],
  },
});

export default Object.assign(DirectTeaserTheme, {
  Blip: BaseDirectTeaserTheme.Blip,
  Button,
  Link: BaseDirectTeaserTheme.Link,
});
