import { ArticleTeaserDefaultProps } from '@/components/ArticleTeaser/Default';
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { mergeOptions } from '@/utils/merge';
import { getOcelotConfig } from 'lib/configs/ocelot';
import { ReactNode } from 'react';
import { RecommendedArticles } from './RecommendedArticles';

export interface StandaloneRecommendedArticles extends StandaloneComponentProps<typeof RecommendedArticles> {
  articles?: ArticleTeaserDefaultProps[];
  articlesCount?: number;
  headline?: ReactNode;
  isLoading?: boolean;
}

const mockedSkeletonData = new Array(4).fill(null).map((_, i) => <RecommendedArticles.Skeleton key={i} />);

export const StandaloneRecommendedArticles: StandaloneComponent<StandaloneRecommendedArticles> = ({
  articles,
  articlesCount,
  headline,
  isLoading,
  ...props
}) => {
  const enabled = getOcelotConfig('features.recommendedArticles.enabled');

  if (!enabled || !articles?.length) return <></>;

  const mappedArticles = articles.slice(0, articlesCount ?? 4).map((article, index) => (
    <RecommendedArticles.ListItem key={index}>
      <RecommendedArticles.ArticleTeaser
        {...article}
        options={mergeOptions({ variant: 'vertical' }, props.options?.$articleTeaser, article.options)}
      />
    </RecommendedArticles.ListItem>
  ));

  const tiles = isLoading ? mockedSkeletonData : mappedArticles;

  return (
    <RecommendedArticles {...props}>
      <RecommendedArticles.Headline>{headline ?? 'Rekommenderad läsning'}</RecommendedArticles.Headline>
      <RecommendedArticles.List>{tiles}</RecommendedArticles.List>
    </RecommendedArticles>
  );
};

export type RecommendedArticlesData = Pick<
  ArticleTeaserDefaultProps,
  'caption' | 'headline' | 'description' | 'image' | 'links'
>[];
